/*.header__container {
  background-color: #0349d2;
  color: white;
  padding: 20px 60px;
  height: 25vh;
}

.header__container--content {
  display: flex;
  justify-content: space-between;
}

.page__container {
  position: relative;
  min-height: 75vh;
  display: flex;
  justify-content: center;
  padding: 0 60px;
  background: #f3f3f3;
}

.page__container--content {
  position: relative;
  width: 100%;
  background: white;
  top: -10vh;
  border-radius: 5px;
  box-shadow: 0 0 1px 1px #eeeeee;
  padding: 40px;
}

@media (max-width: 600px) {
  .page__container {
    padding: 0;
  }
}
*/

.app__container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f3f3f3;
}

.page__background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 25vh;
  background: inherit;
  z-index: 1 !important;
}