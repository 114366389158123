.dropzone {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  border: solid 2px #dfdfdf;
  justify-content: space-between;
}

.dropzone__container {
  min-height: 120px;
  padding: 5px;
  border-radius: 10px;
  border: dashed 2px #2d9cdb;
  background-color: #ebf2f5;
  color: inherit;
  outline: none;
  transition: 0.24s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-sizing: content-box;
}

.dropzone__container:hover {
  border-color: #2d9cdb;
  background-color: #d4e6ee;
}

.dropzone__container--content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  border-radius: 10px;
  z-index: 1;
}

.dropzone__container--loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 0;
  bottom: 10px;
  left: 0;
  background-image: conic-gradient(
    #1976d2 60deg,
    transparent 120deg
  );
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    right: -50%;
    left: 0;
  }
  50% {
    right: 0;
    left: -50%;
  }
  100% {
    transform: rotate(360deg);
    right: -50%;
    left: 0;
  }
}
