body {
  margin: 0;
  font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif,
    monospace;
}

html {
  font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif;
  background-color: #262933;
  scroll-behavior: smooth;
}
