.data-grid-container .data-grid .cell.error {
  background: #f8d7da;
  color: #721c24;
}
.data-grid-container .data-grid .cell.error > div.text {
  text-align: center;
}
.data-grid-container .data-grid .cell.active {
  background: #cce5ff;
  color: #004085;
}
.data-grid-container .data-grid .cell.active > div.text {
  text-align: center;
}
.data-grid-container .data-grid .cell.inactive {
  background: #fff3cd;
  color: #856404;
}
.data-grid-container .data-grid .cell.inactive > div.text {
  text-align: center;
}
.data-grid-container .data-grid .cell.edited {
  background: #d4edda;
  color: #155724;
}
.data-grid-container .data-grid .cell.edited > div.text {
  text-align: center;
}

.data-grid-container .data-grid .cell,
.data-grid-container .data-grid.wrap .cell,
.data-grid-container .data-grid.wrap .cell.wrap,
.data-grid-container .data-grid .cell.wrap,
.data-grid-container .data-grid.nowrap .cell.wrap,
.data-grid-container .data-grid.clip .cell.wrap {
  white-space: nowrap !important;
  padding: 5px 10px !important;
  height: calc(100% - 10px) !important;
  /* width: 100% !important; */
  width: calc(100% - 20px) !important;
}

.row-handle {
  cursor: grab !important;
}
.row-no-handle {
  cursor: default !important;
}

table {
  position: relative;
  border-collapse: collapse;
  top: 0;
  left: 0;
}

.multi-select {
  height: 30px;
}

.dropdown-heading {
  border-color: white !important;
  height: 30px !important;
}

#sheetContainer {
  table:after {
    position: fixed;
    left: 20px;
    top: 0px;
    height: 1px;
    background: #ddd;
    content: '';
    width: calc(100% - 62px);
    display: block;
  }
  thead th {
    position: sticky;
    top: 0;
  }

  thead {
    left: 0;
    z-index: 1;
  }

  tbody th {
    position: sticky;
    left: 0;
  }

  .headerText:after {
    position: fixed;
    left: 20px;
    top: 38px;
    height: 1px;
    background: #ddd;
    content: '';
    width: calc(100% - 62px);
    display: block;
  }

  tbody:after {
    position: fixed;
    left: 20px;
    top: 75px;
    height: 1px;
    background: #ddd;
    content: '';
    width: calc(100% - 62px);
    display: block;
  }
}

.checkboxlevita {
  zoom: 1.5;
  margin-bottom: 4px;
}

.checkboxlevita:checked {
  background-color: #04b7d2;
}

.data-grid-container table#editEmployees thead tr th:nth-child(8) {
  min-width: 300px !important;
}

#modalGenerateLayouts > div > div {
  max-width: 100%;
}

.data-grid-container .data-grid .cell.selected.editing > input.data-editor {
  border: 0px;
  width: calc(100% - 6px);
  height: 100%;
  z-index: 2;
  position: relative;
  // width: calc(100% x 10);
  max-width: 280px;
  text-align: center;
  position: relative;
  top: 0px;
  left: 0px;
}

td.cell.selected.editing {
  padding: 0 3px 1px 0;
  position: relative;
  height: calc(100% - 1px) !important;
  width: calc(100% - 3px) !important;
}
